if (document.readyState === "loading") {
    document.addEventListener("DOMContentLoaded", initHelpApp)
} else {
    initHelpApp()
}

async function initHelpApp() {
    setTimeout(async () => {
        const [{createApp}, HelpNavigation] = await Promise.all([
            import("vue"),
            import("@/components/Frontend/HelpNavigation.vue")
        ])

        const helpApp = createApp({})
        helpApp.component("help-navigation", HelpNavigation.default)

        if (document.getElementById("helpCatalogMobile")) {
            helpApp.mount("#helpCatalogMobile")
        }
    }, 400)
}

export default function (activateModule) {
    const copyLinkNotify = document.getElementById("copy-link-notify")
    const navigationCatalog = document.getElementById("navigationCatalog")
    const helpCatalogMobile = document.getElementById("helpCatalogMobile")

    if (activateModule === "navigation") {
        navigationHandler()
    } else {
        searchHandler(null)
    }

    if (navigationCatalog && helpCatalogMobile) {
        navigationCatalog.addEventListener("click", navigationHandler, {passive: true})
    }

    function navigationHandler() {
        navigationCatalog.classList.toggle("_open")
        if (helpCatalogMobile) {
            helpCatalogMobile.classList.toggle("close-catalog")
        }
    }

    function searchHandler(linkButton) {
        const input = document.createElement("input")
        input.style.cssText = "opacity: 0; position: absolute;"
        input.value = window.location.href
        input.setAttribute("readonly", true)
        input.setAttribute("contenteditable", true)
        document.body.appendChild(input)
        input.select()
        input.setSelectionRange(0, 99999)
        document.execCommand("copy")
        document.body.removeChild(input)

        if (linkButton) {
            linkButton.title = "Der Link wurde kopiert."
            linkButton.focus()
        } else {
            document.querySelector(".help-search input").focus()
        }

        if (copyLinkNotify && linkButton) {
            copyLinkNotify.style.display = "block"
            setTimeout(() => {
                copyLinkNotify.style.display = "none"
            }, 2000)
        }
    }
}

document.addEventListener(
    "DOMContentLoaded",
    () => {
        function magnify(img, zoom, cursorPos) {
            let glass, w, h, bw

            /* Create magnifier glass: */
            glass = document.createElement("DIV")
            glass.setAttribute("class", "img-magnifier-glass")

            /* Insert magnifier glass: */
            img.parentElement.insertBefore(glass, img)

            /* Set background properties for the magnifier glass: */
            glass.style.backgroundImage = "url('" + img.src + "')"
            glass.style.backgroundRepeat = "no-repeat"
            glass.style.backgroundSize = (img.width * zoom) + "px " + (img.height * zoom) + "px"
            bw = 3
            w = glass.offsetWidth / 2
            h = glass.offsetHeight / 2

            /* Position the magnifier glass based on initial cursor position */
            moveMagnifier({x: cursorPos.x, y: cursorPos.y})

            /* Execute a function when someone moves the magnifier glass over the image: */
            glass.addEventListener("mousemove", moveMagnifier)
            img.addEventListener("mousemove", moveMagnifier)

            /* and also for touch screens: */
            glass.addEventListener("touchmove", moveMagnifier)
            img.addEventListener("touchmove", moveMagnifier)

            // Remove magnifier glass on mouseleave
            glass.addEventListener("mouseleave", function () {
                if (activeGlass) {
                    activeGlass.remove()
                    activeGlass = null
                }
            })

            glass.addEventListener("click", function () {
                if (activeGlass) {
                    activeGlass.remove()
                    activeGlass = null
                }
            })

            function moveMagnifier(e) {
                let pos, x, y
                if (e.type === "mousemove" || e.type === "touchmove") {
                    pos = getCursorPos(e)
                } else {
                    pos = e
                }
                x = pos.x
                y = pos.y

                /* Prevent the magnifier glass from being positioned outside the image: */
                if (x > img.width - w / zoom) {
                    x = img.width - w / zoom
                }
                if (x < w / zoom) {
                    x = w / zoom
                }
                if (y > img.height - h / zoom) {
                    y = img.height - h / zoom
                }
                if (y < h / zoom) {
                    y = h / zoom
                }

                /* Set the position of the magnifier glass: */
                glass.style.left = (x - w) + "px"
                glass.style.top = (y - h) + "px"

                /* Display what the magnifier glass "sees": */
                glass.style.backgroundPosition = "-" + ((x * zoom) - w + bw) + "px -" + ((y * zoom) - h + bw) + "px"
            }

            function getCursorPos(e) {
                let a, x = 0, y = 0
                e = e || window.event
                /* Get the x and y positions of the image: */
                a = img.getBoundingClientRect()
                /* Calculate the cursor's x and y coordinates, relative to the image: */
                x = e.pageX - a.left
                y = e.pageY - a.top
                /* Consider any page scrolling: */
                x = x - window.pageXOffset
                y = y - window.pageYOffset
                return {x, y}
            }

            return glass // Return the created glass element for later removal
        }

        let tools = document.querySelector(".tools")
        let activeGlass = null

        if (tools) {
            document.querySelectorAll(".wp-block-image").forEach((imageContainer) => {
                if(!imageContainer.querySelector("a")) {
                    let superZoomDiv = document.createElement("div")
                    superZoomDiv.className = "superZoom"
                    imageContainer.appendChild(superZoomDiv)

                    superZoomDiv.addEventListener("click", function () {
                        let modal = document.createElement("div")
                        modal.id = "myModal"
                        modal.className = "modal"

                        let closeBtn = document.createElement("span")
                        closeBtn.className = "close"
                        closeBtn.innerHTML = "&times;"

                        let modalImg = document.createElement("img")
                        modalImg.className = "modal-content"
                        modalImg.id = "img01"
                        modalImg.src = imageContainer.querySelector("img").src

                        let caption = document.createElement("div")
                        caption.id = "caption"
                        caption.innerText = imageContainer.querySelector("img").alt

                        modal.appendChild(closeBtn)
                        modal.appendChild(modalImg)
                        modal.appendChild(caption)

                        document.querySelector(".tools").appendChild(modal)

                        modal.style.display = "flex"

                        closeBtn.onclick = function () {
                            modal.remove()
                        }

                        caption.onclick = function () {
                            modal.remove()
                        }

                        window.addEventListener("keydown", function (event) {
                            if (event.key === "Escape") {
                                modal.remove()
                            }
                        })

                        modal.onclick = function (e) {
                            if (e.target === modal) {
                                modal.remove()
                            }
                        }
                    })

                    imageContainer.querySelector("img").addEventListener("click", function (event) {
                        event.stopPropagation()

                        if (activeGlass) {
                            activeGlass.remove()
                            activeGlass = null
                        }

                    const cursorPos = {x: event.offsetX, y: event.offsetY}
                    activeGlass = magnify(event.target, 2, cursorPos)
                    })
                }
            })

            document.addEventListener("click", function (event) {
                if (activeGlass && !event.target.closest(".wp-block-image")) {
                    activeGlass.remove()
                    activeGlass = null
                }
            })

            document.addEventListener("keydown", function (event) {
                if (event.key === "Escape" && activeGlass) {
                    activeGlass.remove()
                    activeGlass = null
                }
            })
        }

        const helpContainerWrapper = document.querySelector(".help-container__wrapper")
        const helpArrowContainer = document.querySelector(".help-arrow-container")
        const handleScroll = () => {
            const wrapperRect = helpContainerWrapper.getBoundingClientRect()
            const arrowHeight = helpArrowContainer.offsetHeight
            const viewportHeight = window.innerHeight

            if (wrapperRect.bottom <= viewportHeight) {
                helpArrowContainer.style.position = "absolute"
                helpArrowContainer.style.top = `${helpContainerWrapper.offsetHeight - arrowHeight}px`
            } else {
                helpArrowContainer.style.position = "fixed"
                helpArrowContainer.style.top = "50%"
            }
        }
        if (helpArrowContainer) {
            helpArrowContainer.style.transition = "top 0.3s ease"
            window.addEventListener("scroll", handleScroll)
            handleScroll()
        }
    },
    {passive: true}
)
